<template>
	<div>
		<div class="px-4 py-4">
			<section aria-labelledby="notes-title">
				<div class="bg-white shadow sm:rounded-lg sm:overflow-hidden">
					<div class="divide-y divide-gray-200">
						<div class="px-4 py-3 sm:px-6">
							<h2 id="responses-title" class="text-2xl font-medium text-gray-900">Related Activities</h2>
						</div>
					</div>
				</div>
			</section>
		</div>
	</div>
</template>

<script lang="ts" setup>
	import { useActivityStore } from '@modules/activities/store';

	const activityStore = useActivityStore();
</script>

<style scoped></style>
